import axios from "axios";
import { Cookies } from "react-cookie";
import { AddInfoBoxMessage } from "../InfoBoxManager";

let api_url = process.env.REACT_APP_API_URL + 'api/AdminPanel/Servers/';
const cookies = new Cookies();

const GetServer = (id, SetServer) => 
{
    axios.get(api_url + "Get/" + id, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        SetServer(res.data);
    }).catch((err) => 
    {
        console.error(err);
    });
}

const GetServers = (SetServers, navigate) => 
{
    axios.get(api_url + "GetAllServers", {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        SetServers(res.data);
    }).catch(() => 
    {
        navigate("/")
    });
}

const AddServer = (serverType, SetServers, navigate, SetServerKey) => 
{
    const data = {
        ServerType : serverType
    }
    axios.post(api_url + "AddServerToPanel", data, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        SetServerKey(res.data);
        GetServers(SetServers, navigate);
    })
};

const RemoveServer = (id, SetServers, navigate) => 
{
    axios.delete(api_url + "RemoveServerFromPanel/" + id, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        GetServers(SetServers, navigate);
    });
};

const ExecuteCommand = (serverId, command, successMessage) => 
{
    axios.post(api_url + "ExecuteCommand/" + serverId, command, {headers: {Authorization: "Bearer " + cookies.get("jwt"), "Content-Type": "application/json" }})
    .then((res) => 
    { 
        if(successMessage)
            AddInfoBoxMessage(successMessage, 1);
        else
            AddInfoBoxMessage("The command was successfully sent for execution!", 1);

    }).catch((error) => 
    {
        AddInfoBoxMessage(error.message, 0);
    });
}

const GetStaffChat = (serverId, SetStaffChat, navigate) => 
{
    axios.get(api_url + "GetStaffChat/"+serverId, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        SetStaffChat(res.data);
    }).catch(() => 
    {
        navigate("/")
    });
}

const GetLogs = (serverId, SetServerLogs, navigate) => 
{
    axios.get(api_url + "GetServerLogs/"+serverId, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        SetServerLogs(res.data);
    }).catch((error) => 
    {
        AddInfoBoxMessage(error.message + ` [${error.config.url}]`, 0);
        navigate("/");
    });
}

const GetPunishmentsData = (serverId, SetPunishments, setReload) => 
{
    axios.get(api_url + "GetPunishmentsData/"+serverId, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        SetPunishments(res.data.data);
        setReload(JSON.stringify(new Date().toString()));
    }).catch((error) => 
    {
        AddInfoBoxMessage(error.message + ` [${error.config.url}]`, 0);
    });
}

const RemovePunishment = (serverId, type, dataToRemove, SetPunishments, setReload) => 
{
    const data = {
        ServerId : serverId,
        Type : type,
        Data: dataToRemove
    }

    axios.post(api_url + "RemovePunishment/", data, {headers: {Authorization: "Bearer " + cookies.get("jwt")}})
    .then((res) => 
    {
        AddInfoBoxMessage("Punishment removed", 1);
        GetPunishmentsData(serverId,  SetPunishments, setReload);
    }).catch((error) => 
    {
        AddInfoBoxMessage(error.message + ` [${error.config.url}]`, 0);
    });
}

export { GetServers, AddServer, RemoveServer, ExecuteCommand, GetStaffChat, GetLogs, GetServer, GetPunishmentsData, RemovePunishment };