import { RemovePunishment } from "../../../../Scripts/Requests/ServerRequests";

const SCPBan= ({serverId, ban, setPunishments, ip, setReload} ) => {
    if(!ban)
        return (<></>);

    const banData = ban.split(';');

    const Unban = () => {
        const type = ip ? 1 : 0;
        RemovePunishment(serverId, type, ban, setPunishments, setReload)
        setReload("unban-"+type+"-" + ban);
    }

    return (
        <div className="listWidget" style={{padding:"7px", marginBottom:"10px"}}>
            <div style={{margin:"5px", display:"flex"}}>
                <div style={{width:"20%"}}>
                    <h2>User</h2>
                    <p>Nick: {banData[0]}</p>
                    <p>Id: {banData[1]}</p>
                </div>
                <div style={{width:"20%"}}>
                    <h2>Banned by</h2>
                    <p>{banData[4]}</p>
                </div>
                <div style={{width:"20%"}}>
                    <h2>Reason</h2>
                    <p>{banData[3]}</p>
                </div>
                <div style={{width:"20%"}}>
                    <h2>Time</h2>
                    <p>{banData[5]}</p>
                </div>
                <div style={{width:"20%"}}>
                    <h2>Actions</h2>
                    <button onClick={() => Unban()} style={{width:"90%"}}>Unban</button>
                    {/* <button style={{width:"90%"}}>Change Time</button> */}
                </div>
                
            </div>
        </div>
    );
};

export default SCPBan;